import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../components/Select';
import Authentication from '../../../modules/Authentication';
import machineIcon from '../../../res/images/machine.svg';
import DateTime from 'luxon/src/datetime';
import {
    addParent,
    findLatestCluster,
    formatClientTitle,
    getAllClustersByClients,
} from '../../../modules/MachineUtils';
import forwardIcon from '../../../res/images/right_circle.svg';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { reloadMachines } from '../AdminSection';
import { setAdminClustersById } from '../../../store/actions/admin';

const useStyles = makeStyles({
    dialog: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
    },
    dialogMessage: {
        fontSize: '20px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formLabelTitle: {
        fontWeight: 'bold',
        width: '100%',
    },
    formField: {
        width: '50%',
    },
    formFieldUser: {
        width: '80%',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '220px',
    },
});

const COMPRESSOR_SETTINGS = [
    { value: 'quiet', label: 'Quiet mode - 40%' },
    { value: 'standard', label: 'Standard - 50%' },
    { value: 'enhanced', label: 'Enhanced - 60%' },
];

const gApiClient = Authentication.getAPIClient();

const formatMachineLabel = (machine, clusterById) => {
    let clusterLabel;
    const latestCluster = findLatestCluster(machine);

    if (!latestCluster) {
        clusterLabel = 'No cluster (disassembled)';
    } else {
        clusterLabel = formatClientTitle(clusterById[latestCluster]);
    }

    return `${machine.alias} - ${clusterLabel}`;
};

export default function MachineCoolingMode(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const machinesById = filter.allMachines || {};
    const clustersById = filter.clustersById || {};
    const machineItems =
        Object.keys(clustersById).length > 0
            ? Object.values(machinesById).map((m) => ({
                  value: m._id,
                  label: formatMachineLabel(m, clustersById),
              }))
            : [];
    const [compressorSetting, setCompressorSetting] = useState();
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const formsWindow = useRef(null);

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];
        if (!filter.allMachines) {
            promises.push(reloadMachines(dispatch));
        }
        if (!filter.clustersById) {
            promises.push(
                (async () => {
                    const today = DateTime.local();
                    const results = await gApiClient.callApi(
                        `admin/getFilterClusters?no_capsule_count=1&return_all=1&start_date=${today.toFormat(
                            'yyyy-MM-dd'
                        )}&end_date=${today.toFormat('yyyy-MM-dd')}`,
                        'GET',
                        {}
                    );
                    let newClients = results.data;
                    newClients.map((item) => addParent(item));
                    let newClusters = getAllClustersByClients(newClients);
                    dispatch(setAdminClustersById(newClusters));
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitForm = async () => {
        props.onLoading(true);
        setError(null);

        try {
            const response = await gApiClient.callApi(
                'admin/setMachineCoolingMode',
                'POST',
                {},
                {
                    machine_id: selectedMachine._id,
                    compressor_setting: compressorSetting.value,
                }
            );

            const responseText = response.data;
            console.log('setMachineCoolingMode', responseText);

            setSuccessMessage(`The update command has been sent successfully.`);
        } catch (e) {
            console.error(e);
            setError(e.message);
            props.onLoading(false);
            return;
        }

        // Clear form after successful submission
        setSelectedMachine(null);
        setCompressorSetting(null);

        props.onLoading(false);
    };

    if (successMessage) {
        return (
            <div ref={formsWindow}>
                <Dialog
                    onClose={() => setSuccessMessage(null)}
                    open={true}
                    style={{ position: 'absolute' }}
                    BackdropProps={{ style: { position: 'absolute' } }}
                    container={() => {
                        return formsWindow.current;
                    }}
                    classes={{ paper: classes.dialog }}
                >
                    <div className={classes.dialogMessage}>
                        {successMessage}
                    </div>
                    <Button
                        className={classes.confirmSubmit}
                        label="Close"
                        onClick={() => setSuccessMessage(null)}
                    />
                </Dialog>
            </div>
        );
    }

    return (
        <div ref={formsWindow}>
            <div className={classes.formRow}>
                <Select
                    className={classes.formFieldFull}
                    items={machineItems}
                    value={
                        selectedMachine
                            ? machineItems.find(
                                  (m) => m.value === selectedMachine._id
                              )
                            : null
                    }
                    onChange={(m) => {
                        setSelectedMachine(machinesById[m.value]);
                    }}
                    rightIcon={machineIcon}
                    hint={'Select Machine'}
                    autoComplete
                />
            </div>
            {selectedMachine && (
                <>
                    <div className={classes.formRow}>
                        <div className={classes.formLabel}>
                            Compressor Setting
                        </div>
                        <Select
                            className={classes.formField}
                            items={COMPRESSOR_SETTINGS}
                            value={compressorSetting}
                            onChange={(v) => {
                                setCompressorSetting(v);
                            }}
                            rightIcon={machineIcon}
                        />
                    </div>

                    {error && (
                        <div
                            className={classes.formRow}
                            style={{
                                justifyContent: 'center',
                                color: 'red',
                                fontWeight: 'bold',
                            }}
                        >
                            <div className={classes.error}>{error}</div>
                        </div>
                    )}

                    {compressorSetting && (
                        <div
                            className={classes.formRow}
                            style={{ justifyContent: 'center' }}
                        >
                            <Button
                                className={classes.confirmSubmit}
                                label="Update Machine"
                                rightIcon={forwardIcon}
                                onClick={onSubmitForm}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
